// Store ID
const account = 'superpao'
const production = 'superpao'

// SalesChannels
const salesChannels = {
  default: 1,
  club: 2,
}

// Salesforce chat
const salesForceIds = {
  id_salesforce: '5735f000000MIO0',
  id_deployment: '5725f000000MHYH',
  id_organization: '00D5f000005VE3f',
}

const salesForceActivated = true

// GTM-IDs
const gtmIdTest = 'GTM-MVWKZ3W'
const gtmIdProduction = 'GTM-T4ZSLH8'

module.exports = {
  account,
  production,

  // Ecommerce Platform
  platform: 'vtex',

  experimental: {
    nodeVersion: 14,
  },

  // Platform specific configs for API
  api: {
    storeId: account,
    environment: 'vtexcommercestable',
    hideUnavailableItems: true,
  },

  // Default channel
  session: {
    currency: {
      code: 'BRL',
      symbol: 'R$',
    },
    locale: 'pt-br',
    channel: `{"salesChannel":"${salesChannels.default}","regionId":""}`,
    country: 'BRA',
    postalCode: null,
    person: null,
  },

  /*
    https://loja.superpao.com.br
    https://secure.superpao.com.br
  */
  // Production URLs
  storeUrl: `https://loja.superpao.com.br`,
  secureSubdomain: `https://secure.superpao.com.br`,
  checkoutUrl: `https://secure.superpao.com.br/api/io/cart`,
  loginUrl: `https://secure.superpao.com.br/api/io/login`,
  accountUrl: `https://secure.superpao.com.br/api/io/account`,

  salesForceIds,
  salesForceActivated,

  // Jitterbit flows urls
  jitterbitUrls: {
    createUser:
      'https://smrholding.wevo.io/iomanager/api/flows/execute/route/prd/user/v1/create',
  },

  // Lighthouse CI
  lighthouse: {
    server: process.env.BASE_SITE_URL || 'http://localhost:9000',
    pages: {
      home: '/',
      pdp: '/presunto-aurora-200g-1639/p',
      collection: '/alimentos/frios-e-embutidos/frios-e-fatiados/presuntos/',
    },
  },

  // SalesChannels
  salesChannels,

  // E2E CI
  cypress: {
    pages: {
      home: '/',
      pdp: '/refrigerante-sem-acucar-coca-cola-pet-2l-1858/p',
      collection: '/bebidas/bebidas/refrigerantes/cola',
      collection_filtered:
        '/bebidas/bebidas/refrigerantes/cola/?category-4=outros&category-1=bebidas&category-2=bebidas&category-3=refrigerantes&category-4=cola&facets=category-4%2Ccategory-1%2Ccategory-2%2Ccategory-3&sort=score_desc&page=0',
      search: '/s/?q=coca',
    },
  },

  hotjarId: '3312556',

  // Meta Tags
  metaTags: {
    'google-site-verification': '1LbYBLt2T-IYrurkpcn_o--Po4Bd1gx2z-rG1UM2ukg',
  },

  analytics: {
    // https://developers.google.com/tag-platform/tag-manager/web#standard_web_page_installation,
    gtmContainerId:
      process.env.FASTSTORE_ENV === 'production' ? gtmIdProduction : gtmIdTest,
  },
}
